<template lang="pug">
.brand-wrapper.om-onboarding.om-onboarding-affiliate
  .container
    .row.justify-content-center
      .col-12.col-md-8.om-onboarding-affiliate-col.d-flex.align-items-center.justify-content-center
        inline-svg(:src="require('@/assets/admin/svg/affiliate/csenge.svg')")
        div
          .om-onboarding-affiliate-title {{ $t('onboarding.affiliate.title') }}
          .om-onboarding-affiliate-desc(v-html="$t('onboarding.affiliate.lead')")
    .row
      .col-12
        .calendly-inline-widget(data-auto-load="false")
    .row
      .col-12
        .text-center.mt-4.brand-link-ternary(@click="skip") {{ $t('onboarding.agency.3.skip') }}
</template>

<script>
  import navigationMixin from '../accountSetup-navigation';

  export default {
    mixins: [navigationMixin],
    mounted() {
      this.initCalendly();
    },

    methods: {
      initCalendly() {
        window.Calendly.initInlineWidget({
          url: 'https://calendly.com/csenge-karakas/optimonkpartnerprogram?utm_source=onboarding',
          parentElement: document.querySelector('.calendly-inline-widget'),
        });

        window.addEventListener('message', this.onMessage);
      },

      onMessage(e) {
        if (e.data.event && e.data.event.indexOf('calendly') === 0) {
          if (e.data.event === 'calendly.event_scheduled') {
            setTimeout(() => {
              this.$store.dispatch('finishOnboarding');
              this.$router.push({ name: 'dashboard' });
            }, 3500);
          }
        }
      },

      skip() {
        this.$store.dispatch('finishOnboarding');
        this.$router.push({ name: 'dashboard' });
      },
    },
  };
</script>

<style lang="sass">
  .om-onboarding-affiliate

    &-title
      font-size: 1.25rem
      font-weight: bold
      color: #505763
      line-height: 1.25rem

    &-desc
      margin-top: 0.5rem
      line-height: 1.75rem
      color: #505763

    .calendly-inline-widget
      min-width: 20rem
      height: 39.375rem

  @media screen and (max-width: 1399px)
    .om-onboarding-affiliate
      .calendly-inline-widget
        height: 55.375rem
  @media screen and (max-width: 991px)
    .om-onboarding-affiliate
      .calendly-inline-widget
        height: 64.375rem
  @media screen and (max-width: 767px)
    .om-onboarding-affiliate
      .calendly-inline-widget
        height: 53.375rem
        margin-top: 2rem
  @media screen and (max-width: 500px)
    .om-onboarding-affiliate-col
      flex-direction: column

      svg
        margin-bottom: 1.25rem

      div
        text-align: center
</style>
